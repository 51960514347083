<template>
  <label class="checkbox-label row" :for="forLabel">
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: "LabelComplex",
  props: {
    forLabel: { type: String },
  },
};
</script>

<style lang="scss" scoped>
@import "/Checkbox";
</style>
